import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css'
import "./layout.css"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Facebook from "../images/facebook-3.svg"
import Instagram from "../images/instagram-3.svg"
import Linkedin from "../images/linkedin-2.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Envelope from "../images/envelope.svg"
import Mobile from "../images/mobile.svg"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>

      <Navbar fixed="top" expand="lg" variant="light" className="shadow py-3">
        <Container>
          <Link className="navbar-brand" to="/">M<span class="red-dot">_</span>Gabryel</Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <AnchorLink className="nav-link" to="/#oferta">Oferta</AnchorLink>
              <Link className="nav-link" to="/realizacje">Realizacje</Link>
              <Link className="nav-link" to="/o-mnie">O mnie</Link>
              <AnchorLink className="nav-link" to="/#kontakt">Kontakt</AnchorLink>
            </Nav>
              <div class="border-right">
                <a href="https://www.facebook.com/marcin.gabryel.5036" target="_blank"><img src={Facebook} width="24px" alt="" /></a>
                <a href="https://www.linkedin.com/in/marcin-gabryel/" target="_blank"><img src={Linkedin} width="24px" class="ml-3" alt="" /></a>
                <a href="https://www.instagram.com/marcin_gabryel/" target="_blank"><img src={Instagram} width="24px" class="ml-3" alt="" /></a>
              </div>
            <AnchorLink className="btn btn-primary ml-4" to="/#kontakt">Wyślij wiadomość</AnchorLink>
          </Navbar.Collapse>
        </Container>
      </Navbar>

        <main>{children}</main>

      <footer class="bg-light pt-5 pb-2">
        <Container>
          <Row>
            <div class="col-sm-12 col-md-4 mb-4">
              <p class="footer-logo mb-3">M<span class="red-dot">_</span>Gabryel</p>
              <p>Zajmuję się tworzeniem nowoczesnych stron i sklepów internetowych. Od ponad 3 lat zawodowo świadczę usługi programistyczne oraz marketingowe.</p>
              <a href="https://www.facebook.com/marcin.gabryel.5036" target="_blank"><img src={Facebook} width="30px" alt="" /></a>
                <a href="https://www.linkedin.com/in/marcin-gabryel/" target="_blank"><img src={Linkedin} width="30px" class="ml-3" alt="" /></a>
                <a href="https://www.instagram.com/marcin_gabryel/" target="_blank"><img src={Instagram} width="30px" class="ml-3" alt="" /></a>
            </div>
            <div class="col-sm-12 col-md-3">
              <p class="menu-title">Nawigacja</p>
              <ul class="footer-nav">
              <li><AnchorLink to="/#oferta">Oferta</AnchorLink></li>
              <li><Link to="/realizacje">Realizacje</Link></li>
              <li><Link to="/o-mnie">O mnie</Link></li>
              <li><AnchorLink to="/#kontakt">Kontakt</AnchorLink></li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-5">
              <p class="menu-title">Dane kontaktowe</p>
              <ul class="footer-nav">
              <li>Mail: <a href="mailto:kontakt@mgabryel.com">kontakt@mgabryel.com</a></li>
              <li>Tel: <a href="tel:739036056">+48739036056</a></li>
              </ul>
            </div>

          </Row>
          <hr></hr>
          <p class="text-muted">Przeglądając tę stronę internetową bez zmian w swojej przeglądarce, wyrażasz zgodę na wykorzystywanie plików cookies.</p>
        </Container>
      </footer>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
